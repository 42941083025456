import React, {useCallback, useState} from 'react'
import Meta from '../components/Meta'
import Holiday from '../components/Holiday'
import {Form, Modal} from 'react-bootstrap'
import socket from '../config/socket'
import {anonTransaction, createTransaction} from '../services/transaction'
import {useDispatch, useSelector} from 'react-redux'
import {useForm, useWatch} from 'react-hook-form'
import {setUser} from '../store/reducers/authSlice'
import {useTranslation} from 'react-i18next'
import {customPrice} from '../helpers/all'
import {Link} from 'react-router-dom'
import Button from '../components/UI/Button'

const NewYear = () => {
    const {t} = useTranslation()
    const user = useSelector((state) => state.auth.user)
    const [payShow, setPayShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const {
        register,
        control,
        handleSubmit,
        getValues,
        formState: {errors, isValid},
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            email: user?.email ? user.email : null,
        },
    })

    const onPay = useCallback(
        (data) => {
            setLoading(true)
            anonTransaction(data)
                .then((res) => res?.link && window.location.replace(res.link))
                .finally(() => setLoading(false))
            if (user.id) {
                socket.on('pay/' + user.id, (e) => {
                    // if (e?.transaction?.status) {
                    //     setStatus(e.transaction.status)
                    // }
                    if (e?.user?.id) {
                        dispatch(setUser(e.user))
                    }
                })
                return () => {
                    socket.off('pay/' + user.id)
                }
            }
        },
        [user]
    )
    return (
        <>
            <Meta title="Всех с новым 2025 годом!" />
            <h2 className="fw-6 mb-4">
                <b className="main-color">Всех с новым 2025 годом!</b>
            </h2>
            <div className="text fs-11 mb-4">
                Хочу поздравить всех пользователей Gikami, друзей, семью и коллег которые в этом году были частью
                моей жизни!
                <br />
                Желаю всем счастья и здоровья в новом году и последующих ста!
                <br />
                Будьте такими же харизматичными, созерцайте каждый день жизни новыми красками, рискуйте и получайте
                удовольствие от своих свершений.
                <br />
                <br />
                Спасибо что вы есть! 🥰
                <br />
                <br />
                <small className="fs-07 text-muted d-block">
                    Если вдруг у кого то появиться желание вложиться вкусностями
                </small>
                <a title="Подарить печеньку!" onClick={() => setPayShow(true)}>
                    Подарить 🍪!
                </a>
            </div>
            <Holiday />
            <Modal show={payShow} onHide={setPayShow} centered>
                <Modal.Header closeButton closeVariant="white">
                    <Modal.Title as="h5" className="fw-7">
                        {t('Подарить печеньку')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="email"
                            placeholder={t('Введите email')}
                            isValid={getValues('email')}
                            isInvalid={errors.email}
                            {...register('email', {
                                required: t('Обязательное поле'),
                            })}
                        />
                        {errors.email && <Form.Text className="text-danger">{errors.email?.message}</Form.Text>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="number"
                            placeholder={t('Введите количество печенек 🍪')}
                            isValid={getValues('price') && !errors.price}
                            isInvalid={errors.login}
                            {...register('price', {
                                required: t('Обязательное поле'),
                                max: {value: 50000, message: `${t('Максимум')} 50 000`},
                                min: {value: 1, message: `${t('Минимум')} 1`},
                            })}
                        />
                        {errors.price && <Form.Text className="text-danger">{errors.price?.message}</Form.Text>}
                    </Form.Group>
                    <Form.Check className="d-flex align-items-center">
                        <Form.Check.Input
                            id="agree"
                            isInvalid={errors.agree}
                            defaultChecked
                            {...register('agree', {required: t('Обязательное поле')})}
                        />
                        <Form.Check.Label htmlFor="agree" className="ms-3 fs-08">
                            {t('Я согласен(-на) с условиями')}&nbsp;
                            <a className="text-decoration-underline" target="_blank" href="/offer">
                                {t('договора оферты')}
                            </a>
                        </Form.Check.Label>
                    </Form.Check>
                </Modal.Body>
                <Modal.Footer>
                    <Link onClick={() => setPayShow(false)} className="btn btn-secondary btn-sm">
                        {t('Отмена')}
                    </Link>
                    <Button
                        isLoading={loading}
                        disabled={!isValid}
                        onClick={handleSubmit(onPay)}
                        className="btn btn-primary btn-sm"
                    >
                        {t('Перейти к оплате')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NewYear
