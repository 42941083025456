import React, {memo, useState, useEffect, useRef} from 'react'
import {Badge, Container, Form, Nav} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {
    IoAddCircleOutline,
    IoChevronBackOutline,
    IoMoon,
    IoNotificationsOutline,
    IoPerson,
    IoSettingsOutline,
    IoSunny,
} from 'react-icons/io5'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {customImage} from '../helpers/all'
import {setTheme, updateOptions} from '../store/reducers/settingsSlice'
import CustomModal from './CustomModal'
import MobileNav from './MobileNav'

const Header = memo(({headerTitle, headerTitleClick, scrollY}) => {
    const {t} = useTranslation()
    const user = useSelector((state) => state.auth.user)
    const isAuth = useSelector((state) => state.auth.isAuth)
    const notification = useSelector((state) => state.notification.notification)
    const message = useSelector((state) => state.notification.message)
    const transaction = useSelector((state) => state.notification.transaction)
    const options = useSelector((state) => state.settings.options)
    const theme = useSelector((state) => state.settings.theme)
    const image = customImage({item: user, type: 'user', size: 'mini'})
    const {chapterId} = useParams()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showOptions, setShowOptions] = useState(false)
    const [showMenu, setShowMenu] = useState(true)

    useEffect(() => {
        if (scrollY > 100 && showMenu) {
            setShowMenu(false)
        } else if (scrollY < 100 && !showMenu) {
            setShowMenu(true)
        }
    }, [scrollY, showMenu])

    useEffect(() => {
        if (theme == 'dark' && options?.color === '#111') {
            dispatch(updateOptions({...options, color: '#fff'}))
        } else if (theme == 'light' && options?.color === '#fff') {
            dispatch(updateOptions({...options, color: '#111'}))
        }
    }, [theme])

    return (
        <>
            <header className={showMenu ? 'show' : 'hide'}>
                <div className="col-xxl-7 col-xl-9 col-lg-10 col-md-11 col-sm-12 m-auto d-flex h-100 align-items-center">
                    <Container className="h-100 p-0">
                        <Nav className="align-items-center">
                            <Nav.Link as={NavLink} to="/" className="d-flex align-items-center">
                                <div className="position-relative">
                                    <img src="/logo.png" alt={process.env.REACT_APP_SITE_NAME} height={45} />
                                </div>
                                {!chapterId && <div className="logo-text fw-7 ps-2">Gikami</div>}
                            </Nav.Link>
                            {headerTitle ? (
                                <Nav.Link
                                    className="d-flex d-sm-flex d-lg-none d-xxl-flex align-items-center line-height-100"
                                    onClick={() => headerTitleClick && headerTitleClick()}
                                >
                                    {headerTitle}
                                </Nav.Link>
                            ) : (
                                chapterId && (
                                    <Nav.Link
                                        className="d-flex d-sm-flex d-lg-none d-xxl-flex align-items-center line-height-100"
                                        onClick={() => navigate(-1)}
                                    >
                                        <span className="fs-09">{t('Назад')}</span>
                                    </Nav.Link>
                                )
                            )}
                            <Nav.Link as={NavLink} to="/" className="d-none d-lg-flex" end>
                                Главная
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/newyear" className="d-inline-flex d-lg-flex">
                                🎄<span className="d-none d-lg-flex"> С новым годом! 🥳</span>
                            </Nav.Link>
                            {/* {isAuth && (
                                <Nav.Link as={NavLink} to="/chat" className="d-none d-lg-flex">
                                    Чат
                                </Nav.Link>
                            )} */}
                        </Nav>
                        <Nav className="d-flex align-items-center">
                            {isAuth && chapterId && (
                                <Nav.Link
                                    className="d-flex align-items-center"
                                    onClick={() => setShowOptions((status) => !status)}
                                >
                                    <IoSettingsOutline size={25} className="light-gray fs-17" />
                                </Nav.Link>
                            )}
                            {isAuth && (
                                <>
                                    <Nav.Link as={NavLink} to="/add" className="d-flex">
                                        <IoAddCircleOutline size={25} className="light-gray fs-17" />
                                    </Nav.Link>
                                    <Nav.Link as={NavLink} to="/notifications" className="d-none d-lg-flex ">
                                        <div className="position-relative">
                                            <IoNotificationsOutline size={25} className="light-gray fs-17 " />
                                            {notification > 0 && (
                                                <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill">
                                                    {notification}
                                                </span>
                                            )}
                                        </div>
                                    </Nav.Link>
                                </>
                            )}
                            <Nav.Link
                                className="d-none d-sm-none d-lg-flex"
                                as={NavLink}
                                to={isAuth ? '/profile' : '/login'}
                            >
                                <div className="position-relative person">
                                    {isAuth ? (
                                        <>
                                            <img src="/images/cap.png" className="cap" />
                                            <img src={image} className="header-avatar" />
                                        </>
                                    ) : (
                                        <IoPerson className="light-gray fs-17" />
                                    )}
                                    {(message > 0 || transaction > 0) && (
                                        <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill">
                                            {Number(message ?? 0) + Number(transaction ?? 0)}
                                        </span>
                                    )}
                                </div>
                            </Nav.Link>
                        </Nav>
                    </Container>
                </div>
            </header>
            <div className="fake-header" />

            <MobileNav />

            <CustomModal show={showOptions} setShow={setShowOptions} title={t('Настройки')}>
                <Form.Label>{t('Тема')}</Form.Label>
                <p>
                    <a onClick={() => dispatch(setTheme(!theme || theme == 'dark' ? 'light' : 'dark'))}>
                        {!theme || theme == 'dark' ? (
                            <div className="d-flex align-items-center sun">
                                <IoSunny className="me-2" size={22} />
                                <span className="fs-09 fw-5 line-height-100">
                                    {t('Светлая')}
                                    <Badge pill className="ms-2">
                                        Beta
                                    </Badge>
                                </span>
                            </div>
                        ) : (
                            <div className="d-flex align-items-center moon">
                                <IoMoon className="me-2" size={22} />
                                <span className="fs-09 fw-5 line-height-100">{t('Темная')}</span>
                            </div>
                        )}
                    </a>
                </p>
                <Form.Label>
                    {t('Размер текста')}
                    <Badge>{options?.fontSize}</Badge>
                </Form.Label>
                <Form.Range
                    className="mb-4"
                    value={options?.fontSize}
                    onChange={(e) => dispatch(updateOptions({...options, fontSize: Number(e.target.value)}))}
                    step={1}
                    min={15}
                    max={19}
                />
                <Form.Label className="mb-3">{t('Цвет текста')}</Form.Label>
                <div className="d-flex align-items-center justify-content-between">
                    <a
                        className={'color-item color-item-1' + (options?.color === '#fff' ? ' active' : '')}
                        onClick={() => dispatch(updateOptions({...options, color: '#fff'}))}
                    />
                    <a
                        className={'color-item color-item-2' + (options?.color === '#ddd' ? ' active' : '')}
                        onClick={() => dispatch(updateOptions({...options, color: '#ddd'}))}
                    />
                    <a
                        className={'color-item color-item-3' + (options?.color === '#999' ? ' active' : '')}
                        onClick={() => dispatch(updateOptions({...options, color: '#999'}))}
                    />
                    <a
                        className={'color-item color-item-4' + (options?.color === '#888' ? ' active' : '')}
                        onClick={() => dispatch(updateOptions({...options, color: '#888'}))}
                    />
                    <a
                        className={'color-item color-item-5' + (options?.color === '#777' ? ' active' : '')}
                        onClick={() => dispatch(updateOptions({...options, color: '#777'}))}
                    />
                    <a
                        className={'color-item color-item-6' + (options?.color === '#111' ? ' active' : '')}
                        onClick={() => dispatch(updateOptions({...options, color: '#111'}))}
                    />
                </div>
            </CustomModal>
        </>
    )
})

export default Header
