import {apiRoutes} from '../config/api'
import {$api, $authApi} from './index'

const createTransaction = async (data) => {
    const response = await $authApi.post(apiRoutes.TRANSACTIONS, data)
    return response?.data
}
const anonTransaction = async (data) => {
    const response = await $api.post(apiRoutes.ANON_TRANSACTIONS, data)
    return response?.data
}
const getTransactions = async (data) => {
    const response = await $authApi.get(apiRoutes.TRANSACTIONS, data)
    return response?.data
}

export {createTransaction, anonTransaction, getTransactions}
