import React from 'react'
import Meta from '../components/Meta'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Holiday from '../components/Holiday'

const Home = () => {
    const {t} = useTranslation()
    return (
        <>
            <Meta
                title={process.env.REACT_APP_SITE_DESCRIPTION}
                description={process.env.REACT_APP_SITE_DESCRIPTION}
            />
            <div className="header-home-fon">
                <div className="row">
                    <div className="col-md-7">
                        <div className="header-home-box mb-3">
                            <div className="d-flex align-items-center mb-3">
                                <div>
                                    <img
                                        src="/apple.png"
                                        alt={process.env.REACT_APP_SITE_NAME}
                                        height={80}
                                        className="me-3 logo-img d-none d-md-flex"
                                    />
                                </div>
                                <div>
                                    <h1 className="mb-0">
                                        <strong>{t('Публикуйся вместе с Gikami')}</strong>
                                    </h1>
                                </div>
                            </div>
                            <p className="fw-5">
                                {t(
                                    'Gikami - это платформа, где твоя творческая энергия обретает свободу. Здесь ты можешь делиться своими идеями, создавать, вдохновлять и быть услышанным.'
                                )}
                            </p>

                            <Link className="d-block d-md-inline-block btn btn-primary me-3 mb-3" to="/reg">
                                {t('Создать профиль')}
                            </Link>
                            <Link className="d-block d-md-inline-block btn btn-secondary mb-3" to="/login">
                                {t('Начать читать')}
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-5 position-relative text-center">
                        <img src="/images/home/home-1.png" className="d-none d-md-flex" width="80%" />
                    </div>
                </div>
            </div>

            <div className="home-box-2">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="h1 mb-5">
                            <strong>{t('Твой голос – в центре внимания')}</strong>
                        </h2>
                        <div className="text-center">
                            <img src="/images/home/home-2.png" width="60%" alt="Твой голос – в центре внимания" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="home-box-card-info mb-3 home-bg-red">
                            <h4>{t('Публикация без ограничений')}</h4>
                            <div>
                                {t(
                                    'Gikami предоставляет тебе полную свободу в выборе жанра, стиля и формата публикации. Делись стихами, прозой, эссе, переводами, сценариями, статьями –  ты сам решаешь, чем делиться с миром.'
                                )}
                            </div>
                        </div>
                        <div className="home-box-card-info me-5">
                            <h4>{t('Простота и удобство')}</h4>
                            <div>
                                {t(
                                    'Интуитивный интерфейс и удобные инструменты для форматирования текста, загрузки файлов и настройки видимости твоих произведений сделают процесс публикации максимально простым и приятным'
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-box-2">
                <div className="row">
                    {' '}
                    <h2 className="h1 mb-4">
                        <strong>{t('Найди свою аудиторию')}</strong>
                    </h2>
                    <div className="col-md-6">
                        <div className="home-box-card-info mb-3">
                            <h4>{t('Общайся с единомышленниками')}</h4>
                            <div>
                                {t(
                                    'Gikami - это не просто платформа, это живое сообщество авторов и читателей. Комментируй, общайся, делись опытом, находи вдохновение в творчестве других пользователей.'
                                )}
                            </div>
                        </div>
                        <div className="home-box-card-info ms-3 mb-3">
                            <h4>{t('Получай обратную связь')}</h4>
                            <div>
                                {t(
                                    'Получай отзывы от читателей и узнавай, что им нравится в твоем творчестве. Используй эти знания для дальнейшего развития своего таланта.'
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="text-center">
                            <img src="/images/home/home-3.png" width="60%" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-box-2">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="h1 mb-5">
                            <strong>{t('Публикуй и монетизируй')}</strong>
                        </h2>
                        <div className="text-center">
                            <img src="/images/home/home-4.png" width="60%" alt="Публикуй и монетизируй" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="home-box-card-info mb-3 home-bg-red">
                            <h4>{t('Стань независимым автором')}</h4>
                            <div>
                                {t(
                                    'Gikami позволяет тебе устанавливать цены на свои произведения, получать платежи от читателей и зарабатывать на своем творчестве.'
                                )}
                            </div>
                        </div>
                        <div className="home-box-card-info me-5">
                            <h4>{t('Разнообразные форматы монетизации')}</h4>
                            <div>
                                {t(
                                    'Выбирай наиболее удобный способ монетизации для тебя: продажа отдельных произведений, подписка на доступ к твоему контенту, участие в партнерских программах.'
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-box-2">
                <div className="text-center">
                    <img src="/images/home/home-smile.png" height={230} />
                    <h2 className="h1 mb-2 mt-3 text-center">
                        <strong>{t('Готовы делиться своим талантом с миром?')}</strong>
                    </h2>
                    <p>{t('Зарегистрирутесь на Gikami и станьте частью творческого сообщества!')}</p>
                    <div className="d-flex justify-content-center mt-3">
                        <Link className="btn btn-primary " to="/reg/">
                            {t('Зарегистрироваться')}
                        </Link>
                    </div>
                </div>
            </div>
            <Holiday />
        </>
    )
}

export default Home
