import React from 'react'
import {useSelector} from 'react-redux'
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes} from 'react-router-dom'
import AppLayout from '../layouts/AppLayout'
import AuthRoute from '../layouts/AuthRoute'
import About from '../pages/About'
import Profile from '../pages/account'
import ProfileEdit from '../pages/account/edit'
import Sessions from '../pages/account/edit/Sessions'
import Theme from '../pages/account/edit/Theme'
import Orders from '../pages/account/Order'
import Transactions from '../pages/account/Transaction'
import Activate from '../pages/auth/Activate'
import Login from '../pages/auth/Login'
import Registration from '../pages/auth/Registration'
import RestorePassword from '../pages/auth/RestorePassword'
import Book from '../pages/Book'
import Catalog from '../pages/Catalog'
import Chapter from '../pages/Chapter'
import Comment from '../pages/Comment'
import Offer from '../pages/document/Offer'
import Policy from '../pages/document/Policy'
import TermAuthor from '../pages/document/TermAuthor'
import TermUser from '../pages/document/TermUser'
import Home from '../pages/Home'
import Manhwa from '../pages/Manhwa'
import NotFound from '../pages/NotFound'
import Notifications from '../pages/Notification'
import Novella from '../pages/Novella'
import Search from '../pages/Search'
import Support from '../pages/Support'
import User from '../pages/user'
import Add from '../pages/Add'
import Public from '../pages/account/Public'
import Subscriptions from '../pages/account/Subscription'
import Bookmarks from '../pages/account/Bookmark'
import Error from '../components/Error'
import Copyright from '../pages/document/Copyright'
import Dmca from '../pages/document/Dmca'
import Chat from '../pages/Chat'
import NewYear from '../pages/NewYear'

const main = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<AppLayout />} errorElement={<Error />}>
            <Route index element={<Catalog />} />
            <Route path="term-user" element={<TermUser />} />
            <Route path="term-author" element={<TermAuthor />} />
            <Route path="policy" element={<Policy />} />
            <Route path="offer" element={<Offer />} />
            <Route path="about" element={<About />} />
            <Route path="copyright" element={<Copyright />} />
            <Route path="dmca" element={<Dmca />} />
            <Route path="search" element={<Search />} />
            <Route path="activate" element={<Activate />} />
            <Route path="newyear" element={<NewYear />} />
            <Route path="chat" element={<Chat />} />
            <Route path="novella" element={<Novella auth />} />
            <Route path="manhwa" element={<Manhwa auth />} />
            <Route path="term-user" element={<TermUser />} />
            <Route path="add" element={<Add />} />
            <Route path="public/:bookId" element={<Book auth />} />
            <Route path="public/:bookId/read/:chapterId" element={<Chapter auth />} />
            <Route path="public/:bookId/read/:chapterId/comment" element={<Comment />} />
            <Route path="public/:bookId/read/:chapterId/comment/:commentId" element={<Comment />} />
            <Route path="notifications" element={<Notifications />} />
            <Route
                path="/profile/*"
                element={
                    <AuthRoute activate>
                        <Routes>
                            <Route index element={<Profile />} />
                            <Route path="edit" element={<ProfileEdit />} />
                            <Route path="edit/sessions" element={<Sessions />} />
                            <Route path="edit/theme" element={<Theme />} />
                            <Route path="transactions" element={<Transactions />} />
                            <Route path="subscriptions" element={<Subscriptions />} />
                            <Route path="bookmarks" element={<Bookmarks />} />
                            <Route path="orders" element={<Orders />} />
                            <Route path="public" element={<Public />} />
                            <Route path="support" element={<Support />} />
                        </Routes>
                    </AuthRoute>
                }
            />
            <Route
                path="/user/:userId"
                element={
                    <AuthRoute activate>
                        <Routes>
                            <Route index element={<User />} />
                        </Routes>
                    </AuthRoute>
                }
            />

            <Route path="*" element={<NotFound />} />
        </Route>
    )
)

const auth = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<AppLayout />} errorElement={<Error />}>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="reg" element={<Registration />} />
            <Route path="recovery" element={<RestorePassword />} />
            <Route path="public/:bookId" element={<Book />} />
            <Route path="public/:bookId/read/:chapterId" element={<Chapter />} />
            <Route path="public/:bookId/read/:chapterId/comment" element={<Comment />} />
            <Route path="term-user" element={<TermUser />} />
            <Route path="term-author" element={<TermAuthor />} />
            <Route path="policy" element={<Policy />} />
            <Route path="newyear" element={<NewYear />} />
            <Route path="offer" element={<Offer />} />
            <Route path="about" element={<About />} />
            <Route path="copyright" element={<Copyright />} />
            <Route path="dmca" element={<Dmca />} />
            <Route path="novella" element={<Novella />} />
            <Route path="manhwa" element={<Manhwa />} />
            <Route path="*" element={<NotFound />} />
        </Route>
    )
)

const AppRouter = () => {
    const isAuth = useSelector((state) => state.auth.isAuth)

    return <RouterProvider router={isAuth ? main : auth} />
}

export default AppRouter
